//
// language switcher
// --------------------------------------------------
//

.m-language-switcher {
    display: inline-flex !important; // sass-lint:disable-line no-important
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;

    @media (min-width: $screen-sm) {
        padding: {
            left: 1em;
            right: 1em;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    .m-language-switcher__item {
        &:not(.m-language-switcher__item--active) .m-language-switcher__item-link {
            color: $link-color;
            transition: $transition-default;

            &:hover {
                color: $link-hover-color;
            }
        }

        &:not(:last-child)::after {
            content: '|';
            padding: {
                left: 0.5em;
                right: 0.5em;
            }
        }
    }

    .m-language-switcher__item--active {
        font-weight: $font-weight-regular;

        .m-language-switcher__item-link {
            pointer-events: none;
            font-weight: $font-weight-bold;
        }
    }

    .m-language-switcher__item-link,
    span {
        display: inline-block;
        padding: {
            left: 0.5em;
            right: 0.5em;
        }
    }

    .m-language-switcher__item--disabled {
        color: $silver;
    }
}
