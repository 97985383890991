//
// brand image
// --------------------------------------------------
//

.a-image-subtitle {
    font-style: italic;
    font-size: $font-size-small;
    display: table-caption;
    caption-side: bottom;
    text-align: left;
}

.ce-media .a-image-subtitle {
    width: 300px;
}
