//
// Main navigation wrapper
// --------------------------------------------------
//

header .navbar-default {
    border-bottom-right-radius: 37px;
    border-start-end-radius: 0 37px;
}

.o-navigation {
    display: inline-block;
    float: none;
    width: 100%;
    vertical-align: top;

    @media (min-width: $screen-sm-min) {
        width: auto;
    }

    &.navbar-nav > li:hover > a,
    &.navbar-nav > li:focus > a {
        color: currentColor;
        background-color: $white;
    }

    li a {
        @media (max-width: $screen-xs-max) {
            font-weight: $font-weight-bold;
        }
    }
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
    color: $gray-base;
}
