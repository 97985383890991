//
// search results
// --------------------------------------------------
//



.m-search-results {
}

.m-search-result {
    margin-bottom: 30px;
}

.m-search-result__headline {
    margin-bottom: 10px;
}

.m-search-result__more {
    display: inline-block;
}

.m-pagination {
    margin-top: 60px;
}

.m-pagination-list__item--previous,
.m-pagination-list__item--next {
    font-family: 'FontAwesome';
    margin-left: 0;
}

.m-pagination-list__item--next a::after {
    content: '\f0da';
}

.m-pagination-list__item--previous a::after {
    content: '\f0d9';
}
