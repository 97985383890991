//
// slider wrapper
// --------------------------------------------------
//

.o-slider {
    padding-right: 0;
    padding-left: 0;

    &:not(.slick-initialized) .o-slider__slide:not(:first-child) {
        display: none;
    }

    //
    // slide container
    // ---------------------

    .o-slider__slide {
        position: relative;
        padding: 0;

        img {
            width: 100%;
            height: auto;

            @media (max-width: $screen-xs-max) {
                width: 100%;
                max-width: initial;
            }
        }
    }

    //
    // slide content wrapper
    // ---------------------

    .o-slider__content {
        position: relative;
        margin: 0 45px;
        font-size: $font-size-base + 1px;
        padding-top: 1em;

        @media (min-width: $screen-sm-min) {
            position: absolute;
            z-index: 1;
            bottom: 10px;
            left: 45px;
            max-width: calc(100% - 90px);
            margin: 0;
            padding: 24px 20px 30px;
            color: $gray-base;
            background: rgba($white, $bg-opacity-slider);
        }

        @media (min-width: $screen-md-min) {
            bottom: 30px;
            left: 60px;
            max-width: 50%;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $text-color;

            @media (max-width: $screen-sm-max) {
                margin-bottom: 5px;
            }
        }

        p {
            margin-bottom: 2rem;
        }

        //
        // slide content variant
        // ---------------------

        &.o-slider__content-invert {
            color: $text-color;
            background: rgba($white, 0.3);

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                color: $text-color;
            }
        }

        &:empty {
            display: none;
        }
    }

}
