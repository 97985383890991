// Magnific Popup
.mfp-wrap {
    figcaption {
        top: auto;
        background: none;
        text-align: left;
        padding-left: 0;
    }

    .mfp-arrow {
        width: 6rem;
        height: 6rem;
        transition: opacity $icon-transition;
        border-radius: 50%;
        background-color: rgba($brand-primary, 0.75);

        &::before {
            display: none;
        }

        &::after {
            top: -1.8rem;
        }

        &.mfp-arrow-left {
            left: $spacing-width-base;

            &::after {
                left: -1.5rem;
            }
        }

        &.mfp-arrow-right {
            right: $spacing-width-base;

            &::after {
                left: -1.5rem;
            }
        }
    }

    .mfp-close {
        top: 2rem;
        right: 42px;
        transition: opacity $icon-transition;
        transform: rotate(45deg);
        text-indent: 999px; // away

        &::before,
        &::after {
            position: absolute;
            top: 0.7rem;
            left: 1.9rem;
            display: block;
            width: 0.1rem;
            height: 3rem;
            content: '';
            border-left: $light-box-border-width solid;
        }

        &::before {
            transform: rotate(90deg);
        }

        &:active {
            top: 2rem;
        }
    }

    // For Facebook Videos
    // http://jira.schalk-office.de:8080/browse/SFR-205
    .fb-video {
        width: 100%;
        height: 100%;

        span {
            width: 100% !important; // sass-lint:disable-line no-important
            height: 100% !important; // sass-lint:disable-line no-important

            iframe {
                width: 100% !important; // sass-lint:disable-line no-important
                height: 100% !important; // sass-lint:disable-line no-important
            }
        }
    }
}
