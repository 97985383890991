//
// brand image
// --------------------------------------------------
//

.a-brand-image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: $brand-image-width-xs;
    height: $brand-image-height-xs;
    margin-left: 15px;
    text-indent: -9999px;
    background-image: url('../Images/logo-xs.png');
    background-repeat: no-repeat;
    background-size: contain;

    @media (min-width: $screen-sm-min) {
        margin: 0;
    }

    @media (min-width: $screen-md-min) {
        width: $brand-image-width-md;
        height: $brand-image-height-md;
        background-image: url('../Images/logo-md.png');
    }
}
