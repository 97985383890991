.m-card {
    position: relative;
    height: 100%;

    .m-card__image {
        @include object-fit;

        &::before {
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        a {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
        }
    }

    .m-card__meta {
        padding: 2px 1px;

        p {
            font-size: $font-size-meta;
            line-height: 1.5;
            margin-bottom: 0;
        }
    }
}
