//
// buttons
// --------------------------------------------------
//

.a-btn {
    @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $btn-border-radius-base);
    @include user-select(none);
    font-weight: $btn-font-weight;
    display: inline-block;
    margin-bottom: 0;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    border: 1px solid transparent;

    touch-action: manipulation;

    &,
    &:active,
    &.active {
        &:focus,
        &.focus {
            @include tab-focus;
        }
    }

    &:hover,
    &:focus,
    &.focus {
        text-decoration: none;
        color: $btn-default-color;
    }

    &:active,
    &.active {
        @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, 0.125));
        outline: 0;
        background-image: none;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        @include opacity(0.65);
        @include box-shadow(none);
        cursor: $cursor-disabled;
        pointer-events: none;
    }
}

//
// button variants
// ---------------------

.a-btn--primary {
    @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);

    &:hover {
        background-color: $btn-primary-hover-bg;
        border-color: $btn-primary-hover-border;
        color: $btn-primary-hover-color;
    }
}

.a-btn--secondary {
    @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);

    &:hover,
    &:focus {
        color: $white;
        background: rgba($brand-primary, 0.8);
    }
}
