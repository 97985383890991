//
// news list
// --------------------------------------------------
//

.t-news-list {
    .t-news-list__article {
        margin-top: $element-divider-margin;
        margin-bottom: 2 * $element-divider-margin;
    }
}
