.o-card-container {
    display: flex;
    flex-wrap: wrap;
    margin: {
        left: - $gutter-width-base / 2;
        right: - $gutter-width-base / 2;
    }

    .m-card {
        flex-basis: 100%;
        max-width: 100%;
        padding: $gutter-width-base / 2;

        @media (min-width: $screen-xs) {
            flex-basis: 50%;
            max-width: 50%;
        }

        @media (min-width: $screen-md) {
            flex: 1 1 25%;
            max-width: 25%;
        }
    }
}
