$screen-xs:             0;
$screen-sm:             576px;
$screen-md:             768px;
$screen-lg:             992px;
$screen-xl:             1260px;
$screen-xxl:            1440px;
$overlay-background:    rgba(33, 33, 33, 0.75);
$white:                 #fff;
$primary-color:         #2d4555;
$secondary-color:       #afafaf;
$tertiary-color:        #d8d7d7;
$modal-shadow-color:    rgba(33, 33, 33, 0.25);

.cookie-consent-backdrop {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 400ms;
    z-index: 1999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $overlay-background;

    &.initialized {
        opacity: 1;
    }

    [type=checkbox] {
        border: 0;
        opacity: 0;

        &+label {
            margin: 0 20px 0 0;
            position: relative;
            padding-left: 10px !important;

            &::before {
                position: absolute;
                left: -14px;
                display: block;
                border-radius: 4px;
                content: '';
                background: #fff;
                top: 5px;
                width: 14px;
                height: 14px;
                border: 1px solid #009de0;
            }

            &::after {
                position: absolute;
                top: 4px;
                left: -10px;
                display: block;
                width: 10px;
                height: 22px;
                transform: rotate(45deg);
                border-width: 0 3px 3px 0;
                border-style: solid;
                border-color: #fff;
            }
        }
    }
}

.cookie-consent-modal {
    font-size: 15px;
    background: $white;
    padding: 16px;
    box-shadow: 0 0 50px 2px $modal-shadow-color;
    width: 95%;
    max-width: 650px;
    max-height: 95%;
    overflow: auto;
}

.cookie-consent-headline {
    margin-top: 0;
    font-size: 22px;
}

.cookie-consent-button-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    @media (min-width: $screen-sm) {
        flex-direction: row;
    }
}

.cookie-consent-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: 1px solid $primary-color;
    background-color: $white;
    color: $primary-color;
    border-radius: 4px;
    word-break: break-word;
    hyphens: auto;
    white-space: pre-wrap;
    font-size: 15px;
    height: 40px;

    + .cookie-consent-button {
        margin-top: 10px;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
        cursor: pointer;
        background-color: $primary-color;
        border: 1px solid $primary-color;
        color: $white;
        outline: 0;
        box-shadow: none;
        text-decoration: none;
    }

    .cookie-consent-button-text {
        margin: 0;
        padding: 0;
    }

    &.loading {
        .cookie-consent-button-text {
            display: none;
        }

        .loading-icon {
            display: block;
        }
    }

    .loading-icon {
        width: 20px;
        height: 20px;
        display: none;
    }

    &#cookie-consent-submit-all-button {
        background-color: $primary-color;
        color: $white;

        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: $white;
            color: $primary-color;
        }
    }

    @media (min-width: $screen-sm) {
        width: 175px;

        + .cookie-consent-button {
            margin-left: 20px;
            margin-top: 0;
        }
    }
}

.cookie-consent-paragraph {
    font-size: 15px;
}

.cookie-consent-input-container {
    width: 100%;
    padding: 0 9px;
    border: 1px solid $tertiary-color;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 30px;
}

.cookie-consent-input-first-line-wrapper {
    width: 100%;
    display: flex;
    height: 30px;
}

.cookie-consent-select-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.cookie-consent-select-inner-wrapper {
    display: flex;
    justify-content: flex-start;

    .cookie-consent-select-input,
    .cookie-consent-select-input + label {
        &:hover {
            cursor: pointer;
        }
    }

    [type='checkbox'] + label {
        margin: 0 10px 0 0;
        padding-left: 6px;
        display: flex;
        align-items: center;

        &::before {
            width: 14px;
            height: 14px;
            border: 1px solid $primary-color;
        }

        @media (min-width: $screen-sm) {
            margin: 0 20px 0 0;
        }
    }

    [type='checkbox']:checked + label::before {
        background-color: $primary-color;
    }

    [type='checkbox']:checked:disabled + label::before {
        background-color: $primary-color;
        border: 1px solid $primary-color;
    }

    [type='checkbox']:checked + label::after {
        content: '';
        height: 11px;
        width: 6px;
        left: -10px;
        top: 4px;
        border-width: 0 2px 2px 0;
        border-color: $white;
    }
}

.cookie-consent-more-info-trigger-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
}

.cookie-consent-more-info-trigger {
    width: 100%;
    background: transparent;
    border: transparent;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;

    &:hover {
        cursor: pointer;
    }

    svg {
        width: 20px;
        height: 20px;
        fill: $primary-color;

        @media (min-width: $screen-sm) {
            display: none;
        }
    }
}

.cookie-consent-more-info-trigger-text {
    display: none;
    white-space: nowrap;
    color: $primary-color;
    padding-right: 10px;
    margin: 0;

    @media (min-width: $screen-sm) {
        display: block;
    }

    &::after {
        position: relative;
        bottom: 0.25em;
        display: inline-block;
        width: 0.3em;
        height: 0.3em;
        top: -0.3rem;
        content: '';
        border-width: 0.05em 0.05em 0 0;
        border-style: solid;
        left: 0.4em;
        transform: rotate(135deg);
    }

    &.open {
        &::after {
            top: -0.1rem;
            transform: rotate(-45deg);
        }
    }
}


.cookie-consent-more-info-container {
    display: none;

    &.open {
        display: block;
        margin: 10px 0;
    }
}

.cookie-consent-more-info-content {
    h5 {
        margin-bottom: 6px;
    }

    p {
        margin: 0 0 20px;
    }

    &.cookie-consent-more-info-content-link-line {
        font-size: 14px;
    }
}



.cookie-consent-info-table {
    padding: 0;
    margin: 8px 0 0;
    font-size: 12px;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;

    th,
    td {
        text-align: left;
        vertical-align: top;
        padding: 4px;
        max-width: 115px;
        overflow: hidden;
        font-size: 12px;

        &:first-child,
        &:nth-child(2) {
            max-width: 60px;
        }
    }
}
