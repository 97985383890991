.a-icon {
    &.a-icon--triangle-left,
    &.a-icon--triangle-right,
    &.a-icon--triangle-bottom {
        width: 2.6rem;
        height: 2.6rem;
        text-indent: -1000vw;
        border: 0;

        &::after {
            position: absolute;
            content: '';
        }
    }

    &.a-icon--triangle-left {
        left: 0;

        &::after {
            top: calc(50% - 6px);
            left: calc(50% - 6px);
            display: block;
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-right: 9px solid $gray-base;
            border-bottom: 6px solid transparent;
        }

        &:hover::after {
            border-right-color: $white;
        }
    }

    &.a-icon--triangle-right {
        right: 0;

        &::after {
            top: calc(50% - 6px);
            left: calc(50% - 3px);
            display: block;
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-left: 9px solid $gray-base;
        }

        &:hover::after {
            border-left-color: $white;
        }
    }

    &.a-icon--triangle-bottom::after {
        top: calc(50% - 3px);
        left: calc(50% - 6px);
        display: block;
        width: 0;
        height: 0;
        border-top: 9px solid $white;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
    }

    &.a-icon--play {
        position: relative;
        display: inline-block;

        &::before,
        &::after {
            position: absolute;
            display: block;
            content: '';
        }

        &::before {
            top: calc(50% - (5rem / 2));
            left: calc(50% - (5rem / 2));
            width: 5rem;
            height: 5rem;
            border: 0.5rem solid $white;
            border-radius: 50%;
        }

        &::after {
            top: calc(50% - 1.25rem);
            left: calc(50% - 0.6rem);
            width: 0;
            height: 0;
            border-top: 1.2rem solid transparent;
            border-bottom: 1.2rem solid transparent;
            border-left: 1.8rem solid $white;
        }
    }
}
