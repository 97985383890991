//
// link download
// --------------------------------------------------
//

.a-link-download {
    span::after {
        font-family: $font-icons;
        margin-left: 5px;
        content: '\f019';
    }
}
