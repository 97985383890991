//
// bootstrap custom styles
// --------------------------------------------------
//

.dropdown:hover .dropdown-menu {
    @media (min-width: $grid-float-breakpoint) {
        display: block;
        margin-top: 0;
    }
}

.navbar-brand-image {
    position: relative;
}

.container > .navbar-header {
    position: relative;
    height: $navbar-height;

    @media (min-width: $screen-sm-min) {
        display: inline-block;
    }
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
    background: $brand-primary;
    color: $white;

    @media (min-width: $grid-float-breakpoint) {
        color: $text-color;
        background: $white;
    }
}

.navbar {
    border: none;

    .navbar-collapse {
        text-align: center;

        @media (min-width: $grid-float-breakpoint) {
            float: right;
        }

        @media (min-width: $screen-md-min) {
            width: calc(100% - 60px);
            text-align: right;
            padding-right: 0;
        }
    }

    .navbar-nav {
        float: none;
    }

    .navbar-collapse .nav {
        text-align: left;
    }
}

.navbar-default i {
    color: $white;
}

.navbar-default .navbar-nav > .open {
    background: $dropdown-bg;
}

.dropdown-menu > li > a {
    font-weight: $font-weight-light;
}

.navbar-nav .open .dropdown-menu > li > a,
.navbar-nav .open .dropdown-menu .dropdown-header {
    @media (max-width: $screen-xs-max) {
        padding: 5px 15px 5px 30px;
    }
}
