//
// header image
// --------------------------------------------------
//

.a-brand-link {
    padding-left: 5px;
    background: $white;

    @media (min-width: $screen-md-min) {
        position: absolute;
        bottom: -51px;
        z-index: 1;
        padding: {
            left: 15px;
            right: 15px;
        }
    }

    img {
        width: 18rem;

        @media (min-width: $screen-md-min) {
            width: auto;
        }
    }
}
