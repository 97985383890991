//
// search box
// --------------------------------------------------
//

.m-searchbox {
    @media (min-width: $screen-xs-min) {
        position: relative;
    }

    &.m-searchbox--open .m-searchbox__form {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
    }

    .m-searchbox__form {
        display: none;
        width: 100%;
        max-height: 100%;

        @media (min-width: $screen-xs-min) {
            width: 400px;
        }

        input,
        .a-btn {
            height: $navbar-height;
            vertical-align: top;
            border: 1px solid $brand-primary;
        }

        .a-btn {
            width: 40px;
        }

        .btn-default .fa {
            color: $brand-secondary;
        }
    }

    &.m-searchbox--searchpage {
        margin-bottom: 40px;

        .m-searchbox__form {
            display: flex;
            //display: block;
            width: 100%;

            input {
                width: 100%;
            }

            .input-group-btn {
                width: auto;
            }
        }

        .a-icon--error {
            display: none;
        }
    }
}
