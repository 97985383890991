//
// type
// --------------------------------------------------
//

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Roboto Light'), local('Roboto-Light'),
    url('../Fonts/Roboto/roboto-v20-latin-300.woff2') format('woff2'),
    url('../Fonts/Roboto/roboto-v20-latin-300.woff') format('woff');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Roboto'), local('Roboto'),
    url('../Fonts/Roboto/roboto-v20-latin-regular.woff2') format('woff2'),
    url('../Fonts/Roboto/roboto-v20-latin-regular.woff') format('woff'),
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Roboto'), local('Roboto'),
    url('../Fonts/Roboto/roboto-v20-latin-700.woff2') format('woff2'),
    url('../Fonts/Roboto/roboto-v20-latin-700.woff') format('woff'),
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $brand-danger;
    font-weight: $font-weight-light;
    line-height: 1.3;
    margin: 0 0 22px;
    font-family: $font-family-base;

    text-rendering: optimizelegibility;
}

h1 {
    font-size: $font-size-h1;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
}

h2 {
    font-size: $font-size-h2;
}

h3 {
    font-size: $font-size-h3;
    margin-bottom: 0.5rem;
}

h4 {
    font-size: $font-size-h4;
}

h5 {
    font-size: $font-size-h5;
}

h6 {
    font-size: $font-size-h6;
}

.lead {
    font-size: $font-size-lead;
    line-height: 1.7;
}

p {
    line-height: 1.7;
}

