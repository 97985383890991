//
// subcontent wrap
// --------------------------------------------------
//

.o-subcontent {
    a::after {
        content: none !important;
    }

    h5 {
        margin-bottom: 5px;
    }

    .o-subcontent__element-container {
        padding-right: $element-divider-margin;
        padding-left: $element-divider-margin;
        background-color: darken($white, 4%);

        &:first-of-type {
            padding-top: $element-divider-margin;
        }

        &:last-of-type {
            padding-bottom: $element-divider-margin;
        }
    }

    .o-subcontent__element-container > * {
        display: inline-block;
        width: 100%;
    }

    > div {
        padding-right: $element-divider-margin;
        padding-left: $element-divider-margin;
    }

    .o-subcontent__element-container + div {
        padding-top: $element-divider-margin;
    }

    > div + .o-subcontent__element-container {
        padding-top: $element-divider-margin;
    }
}
