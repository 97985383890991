//
// news details
// --------------------------------------------------
//

.t-news-details {
    a:not(.a-link-back) {
        @include link-icon-right;
        &:after {
            position: relative;
        }
    }

    .t-news-details__img-wrap {
        float: left !important;
        margin: 0 25px 0 0 !important;

        a::after {
            content: none !important;
        }
    }
}
