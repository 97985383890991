//
// breadcrumb
// --------------------------------------------------
//

.m-dropdown {
    .m-dropdown-toggle {
        display: inline-block;
        float: right;
        width: 20%;
        font-size: 28px;
        line-height: 41px;
        text-align: right;
        padding-right: 15px;

        &:hover {
            cursor: pointer;
        }

        @media (min-width: $grid-float-breakpoint) {
            display: none;
        }
    }

    > a,
    .m-dropdown-submenu > a {
        @media (max-width: $screen-xs-max) {
            display: inline-block !important;
            width: 80%;
        }
    }

    a:focus + .m-dropdown-toggle {
        background: $brand-primary;
    }


    &:hover,
    &:hover .m-dropdown-toggle {
        background: $brand-primary;
    }

    &.active {
        @media (max-width: $grid-float-breakpoint - 1) {
            .dropdown-toggle i {
                background: transparent !important;
            }
        }
    }

    .m-dropdown-menu {
        li {
            position: relative;
        }

        li a,
        > .m-dropdown-menu a,
        > .active a {
            display: block;
            padding: 3px 20px;
            color: $dropdown-link-color;
            white-space: normal;

            &:hover {
                    color: $dropdown-link-hover-color;
                    background-color: $dropdown-link-hover-bg;
            }
        }


        .m-dropdown-submenu > a::after {
            @media (min-width: $grid-float-breakpoint) {
                font-family: $font-icons;
                position: absolute;
                right: 10px;
                content: '\f105';
            }
        }

        .m-dropdown-submenu:hover > .m-dropdown-menu {
            @media (min-width: $grid-float-breakpoint) {
                display: block;
            }
        }

        .m-dropdown-submenu .dropdown-toggle {
            line-height: 31px;
        }

        // sub menu
        .m-dropdown-submenu > .m-dropdown-menu {
            margin: 0;
            padding-left: 25px;
            list-style: none;

            @media (min-width: $grid-float-breakpoint) {
                position: absolute;
                top: 0;
                left: calc(100% + 1px);
                display: none;
                padding: 0;
                background-color: $dropdown-bg;
                box-shadow: 6px 6px 12px rgba($gray-base, 0.175);
            }
        }
    }
}
