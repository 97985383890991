.m-figure {
    overflow: hidden;
    position: relative;

    img,
    a {
        outline: 0;
    }

    // Used for Gallery Slick Slider
    &.m-figure--list {
        img {
            width: 100%;
            height: 100%;

            object-fit: cover;
            object-position: 50% 50%;
        }
    }

    &.m-figure--fullwidth {
        img {
            width: 100%;
            height: auto;
        }
    }
}
