//
// teaser
// --------------------------------------------------
//

.m-teaser {
    position: relative;
    overflow: hidden;

    &::before {
        display: block;
        padding-top: 100%;
        content: '';
    }

    //
    // teaser variants
    // ---------------------

    &.m-teaser-2-3 {
        &::before {
            padding-top: 50%;
        }
    }

    &:not(.m-teaser-2-3):hover .a-teaser-background {
        transform: scale(1.1);
    }

    //
    // teaser contents
    // ---------------------

    .m-teaser__link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .m-teaser__content {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        margin: 5px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @media (min-width: $screen-sm-min) {
            margin: 10px;
        }

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;

            + .m-teaser__headline {
                display: none;
            }
        }
    }

    .m-teaser__headline {
        @extend figcaption;
        background-color: $brand-primary;
        color: $gray-base;
        text-shadow: none;
        text-align: left;
        padding: {
            top: 2rem;
            bottom: 2rem;
        }
    }

    .m-teaser__media {
        @include object-fit;
    }
}
