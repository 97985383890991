//
// metanavigation wrapper
// --------------------------------------------------
//

.o-metanav {
    margin-bottom: 0;

    .container {
        @media (max-width: $screen-xs-min - 1) {
            padding: 0;
        }
    }

    .o-metanav__container {
        line-height: $navbar-height;
        float: none;
        max-width: 100%;
        text-align: center;
        display: flex;
        align-items: center;

        @media (min-width: $screen-sm-min) {
            float: right;
        }

        > *,
        .o-metanav__link {
            display: inline-flex;
            align-self: stretch;
            align-items: center;
        }

        .o-metanav__link {
            padding: {
                right: 5px;
                left: 5px;
            }

            @media (min-width: $screen-sm-min) {
                padding-right: 15px;
                padding-left: 15px;
                display: inline-flex;
                height: 100%;
            }

            .fa {
                display: flex;
                align-items: center;
                height: 100%;
                margin: {
                    left: 0.5em;
                    right: 0.5em;
                }
            }

            .fa-phone {
                @media (max-width: $screen-sm-max) {
                    + span {
                        display: none;
                    }
                }

                @media (min-width: $screen-md-min) {
                    display: none;
                }
            }
        }

        a {
            color: $navbar-inverse-link-color;

            @media (max-width: $screen-xs-max) {
                padding-left: 5px;
                padding-right: 5px;
            }

            &:hover {
                cursor: pointer;
                text-decoration: none;
                color: $navbar-inverse-link-hover-color;
                background-color: $navbar-inverse-link-hover-bg;
            }
        }

        .fa {
            line-height: inherit;
        }
    }
}
