.o-gallery {
    padding-bottom: $spacing-width-base;

    @media (min-width: $screen-lg) {
        .slick-list {
            width: calc(100% + ((100vw - 100%) / 2));
        }
    }

    &:not(.slick-initialized) .gallery-item:not(:first-child) {
        display: none;
    }

    .gallery__item-image {
        width: 35rem;
    }

    &.o-gallery--layout-small .gallery__item-image {
        width: 26rem;
    }

    // Slick Slides which are not active
    .slick-slide {
        margin-right: $gutter-width-base;
        transition: opacity $transition-inactive-slide;

        &:not(.slick-active) {
            opacity: $opacity-inactive-slide;
        }
    }

    .slick-navigation {
        position: absolute;
        display: flex;
        bottom: 0;
        top: auto;
        z-index: 1;
        text-align: center;

        .slick-arrow {
            position: relative;
            width: 3.2rem;
            height: 3.2rem;
            opacity: 0.5;
            border: 0;
            overflow: hidden;
            text-indent: 200%;
            background-color: $brand-primary;
            border-radius: 50%;
            transition: opacity 0.35s linear;

            &:hover {
                opacity: 1;
            }
        }

        .slick-next {
            margin-left: auto;
        }
    }
}
