// Magnific Popup

// Correct Ratio height of Youtube Video
// 16 : 9 calculated heights:

$width-ratio: 95vw;
$width-ratio-sm: 75vw;
$width-ratio-lg: 60vw;

$height-ratio: calc((9 * #{$width-ratio}) / 16);
$height-ratio-sm: calc((9 * #{$width-ratio-sm}) / 16);
$height-ratio-lg: calc((9 * #{$width-ratio-lg}) / 16);

.o-lightbox {
    position: relative;
    width: auto;
    max-width: $width-ratio;
    margin: $spacing-width-base auto;

    @media (min-width: $screen-sm) {
        max-width: $width-ratio-sm;
    }

    @media (min-width: $screen-lg) {
        max-width: $width-ratio-lg;
    }

    img {
        width: 100%;
        height: auto;
    }

    .o-lightbox__description {
        display: inline-block;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 5px;
        color: $light-box-color;
        border-bottom: 1px solid $light-box-color;
    }

    &.o-lightbox--video {
        iframe,
        video {
            width: 100%;
            height: $height-ratio;

            @media (min-width: $screen-sm) {
                height: $height-ratio-sm;
            }

            @media (min-width: $screen-lg) {
                height: $height-ratio-lg;
            }
        }
    }

    &.o-lightbox--facebook-video {
        width: 100%;
        height: $height-ratio;

        @media (min-width: $screen-sm) {
            height: $height-ratio-sm;
        }

        @media (min-width: $screen-lg) {
            height: $height-ratio-lg;
        }
    }

    // in case no js is loaded, hide lightbox
    .mfp-hide {
        display: none !important; // sass-lint:disable-line no-important
    }
}
