//
// teaser container
// --------------------------------------------------
//

$teaserbox-spacing: 10px;

.o-teaserbox {
    width: calc(100% + 2 * #{$teaserbox-spacing});
    margin-right: -$teaserbox-spacing;
    margin-bottom: $element-divider-margin;
    margin-left: -$teaserbox-spacing;

    @media (min-width: $screen-xs-min) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .o-teaserbox__teaser {
        border-bottom-right-radius: 37px;
        max-width: 100%;

        @media (min-width: $screen-xs-min) {
            flex: 0 0 calc(33.3% - 3px);
        }
    }

    .o-teaserbox__teaser-feature {
        @media (max-width: $screen-sm-max) {
            flex: 0 0 100%;
        }

        &::before {
            @media (max-width: $screen-sm-max) {
                content: none;
            }
        }
    }

    .o-teaserbox__teaser-2-3 {
        max-width: 100%;

        @media (min-width: $screen-xs-min) {
            flex: 0 0 66.6%;
        }
    }

    .o-teaserbox__teaser__content {
        padding-left: 10px;

        @media (max-width: $screen-sm-max) {
            position: static;
        }
    }

    .o-teaserbox__headline {
        top: auto;
        bottom: 0;
    }
}
