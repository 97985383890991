//
// pagination
// --------------------------------------------------
//

.m-pagination,
.f3-widget-paginator {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;

    li {
        @extend .a-btn--secondary;
        line-height: 30px;
        display: inline-block;
        min-width: 30px;
        height: 30px;
        padding: 0 10px;
    }

    li a {
        display: block;
        width: 100%;
        height: 100%;
        color: $white;

        &:focus {
            text-decoration: none;
        }
    }

    .m-pagination__current,
    .current {
        @extend .a-btn--primary;

        &:hover {
            background-color: $brand-primary;
        }
    }
}
