.p-increased-contrast-mode {
    $transparent-bg: rgba($gray-darker, 0.9);
    letter-spacing: 0.05em;

    .m-contrast-switch {
        .m-contrast-switch__increase {
            display: none;
        }

        .m-contrast-switch__decrease {
            display: inline-flex;
        }
    }

    .m-language-switcher {
        .m-language-switcher__item:not(.m-language-switcher__item--active) .m-language-switcher__item-link {
            font-weight: $font-weight-bold;
            color: $brand-secondary;
        }

        .m-language-switcher__item-link {
            font-weight: $font-weight-regular;
        }
    }

    h4 {
        font-size: ceil(($font-size-base * 1.7));
    }

    a {
        font-weight: $font-weight-regular;
        color: $brand-secondary;

        &:hover {
            text-decoration: none;
        }
    }

    .m-dropdown {
        .m-dropdown-menu .m-dropdown-submenu .dropdown-toggle .fa {
            color: $brand-secondary;
        }

        a {
            &:focus + .m-dropdown-toggle {
                color: $brand-secondary;
                background: transparent;
            }
        }

        .fa {
            color: $brand-secondary;
        }

        &.open,
        &:hover {
            background: $white;
            color: $brand-secondary;

            a {
                color: $brand-secondary;
            }

            .m-dropdown-toggle {
                background: $white;
                color: $brand-secondary;
            }

            .fa {
                color: $brand-secondary;
            }
        }

        &.active {
            @media (max-width: $screen-md - 1) {
                > a {
                    color: $brand-secondary !important; // sass-lint:disable-line no-important
                    text-decoration: underline;
                }

                .dropdown-toggle i {
                    color: $brand-secondary !important; // sass-lint:disable-line no-important
                }

                &.open a,
                &.open .dropdown-toggle i,
                &:hover a,
                &:hover .dropdown-toggle i {
                    background: $white !important; // sass-lint:disable-line no-important
                    color: $brand-secondary !important; // sass-lint:disable-line no-important
                }
            }
        }
    }

    .navbar-collapse {
        @media (min-width: $screen-md) and (max-width: $screen-md-max) {
            padding: {
                left: 0;
                right: 0;
            }
        }

        li a {
            font-weight: $font-weight-bold;
            letter-spacing: 0.1em;
        }
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: $brand-secondary;

        &:hover,
        &:focus {
            background-color: $white;
            //color: $brand-secondary;

            @media (min-width: $screen-sm) {
                background-color: $brand-secondary;
                color: $white;
            }
        }
    }

    .nav {
        > li > a {
            @media (max-width: $screen-md-max) {
                padding: {
                    left: 0.5em;
                    right: 0.5em;
                }
            }
        }

        .open > a {
            background: $white;
        }

        .open a:hover,
        .open a:focus {
            background-color: $brand-secondary;
            color: $white;
        }
    }

    .navbar-default .navbar-nav > .open > a,
    .navbar-default .navbar-nav > .open > a:hover,
    .navbar-default .navbar-nav > .open > a:focus {
        background-color: $white;
        color: $brand-secondary;
        text-decoration: underline;
    }

    .navbar-default .navbar-nav > .open {
        background: $white;
        color: $brand-secondary;
    }

    .dropdown-menu > .active > a,
    .dropdown-menu > .active > a:hover,
    .dropdown-menu > .active > a:focus,
    .m-dropdown .m-dropdown-menu li a:hover,
    .m-dropdown .m-dropdown-menu > .m-dropdown-menu a:hover,
    .m-dropdown .m-dropdown-menu > .active a:hover {
        background: $brand-secondary;
        color: $white;
    }

    .m-dropdown .m-dropdown-menu .m-dropdown-submenu > .m-dropdown-menu {
        background-color: $white;
    }

    .o-navigation.navbar-nav > li:hover > a,
    .o-navigation.navbar-nav > li:focus > a,
    .navbar-default .navbar-nav > .active > a,
    .navbar-default .navbar-nav > .active > a:hover,
    .navbar-default .navbar-nav > .active > a:focus, {
        background: $white;
        color: $brand-secondary;
    }

    .dropdown-menu {
        background: $white;
        border-color: $white;
    }

    main a {
        text-decoration: underline;
        font-weight: $font-weight-bold;
    }

    // sass-lint:disable class-name-format
    .a-btn--primary,
    .readspeaker-button.rsbtn.readspeaker-button--big .rsbtn_play {
        background-color: $white;
        border: 1px solid $brand-secondary;
        color: $white;
        text-decoration: none;
        font-weight: $font-weight-bold;

        &:hover,
        &:active {
            color: $brand-secondary;
            background-color: $transparent-bg;
            text-decoration: underline;
        }

        @media (min-width: $screen-md) {
            background-color: $transparent-bg;

            &:hover,
            &:active {
                background-color: $white;
            }
        }
    }

    .readspeaker-button.rsbtn.readspeaker-button--big {
        .rsbtn_tooltoggle {
            border-color: $brand-secondary;

            &:hover .rsicn {
                color: $brand-secondary;
            }

            &:focus .rsicn {
                color: $white;
            }
        }

        .rsbtn_play {
            &:hover .rsbtn_text span,
            &:focus .rsbtn_text span {
                color: $brand-secondary;
            }

            &:hover ~ .rsbtn_tooltoggle,
            :focus ~ .rsbtn_tooltoggle {
                color: $brand-secondary;
                border-color: $brand-secondary;
            }
        }

        .rsbtn_toolpanel .rsbtn_tools {
            color: $brand-secondary;
            border-color: $brand-secondary;
            background-color: $white;
        }
    }

    .rsbtn_toolpanel {
        [class*=' rsicn-'],
        [class*=' rsicn-']::before,
        [class^='rsicn-'],
        [class^='rsicn-']::before {
            color: $brand-secondary;
        }
    }

    // sass-lint:enable class-name-format

    .o-slider__content {
        @media (min-width: $screen-sm-min) {
            background-color: $transparent-bg;
            text-shadow: none;
        }

        .a-btn--primary {
            background-color: $white;
            border-color: $brand-secondary;
            color: $brand-secondary;
            text-decoration: none;

            &:hover,
            &:active {
                color: $brand-secondary;
                text-decoration: underline;
            }
        }

        @media (min-width: $screen-md) {
            h3,
            p {
                color: $white;
            }
        }

    }

    .slick-navigation .slick-arrow {
        opacity: 1;
        background-color: $transparent-bg;
    }

    .a-icon.a-icon--triangle-right::after {
        border-left-color: $white;
    }

    .a-icon.a-icon--triangle-left::after {
        border-right-color: $white;
    }

    .m-teaser__headline {
        text-shadow: none;
    }

    .o-footer a {
        color: $brand-secondary;

        &:hover,
        &:active {
            text-decoration: underline;
        }
    }

    .o-subcontent__element-container h5 {
        color: $brand-secondary;
        font-weight: $font-weight-bold;
    }

    .ce-gallery .ce-media .m-lightbox-trigger,
    .ce-gallery figure .m-lightbox-trigger {
        &::after {
            background: $brand-secondary;
        }

        &:hover::after {
            background: $white;
            color: $brand-secondary;
        }
    }

    .ce-gallery figcaption {
        padding: 10px 10px;
    }
}
