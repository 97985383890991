//
// helper classes
// --------------------------------------------------
//

.u-text-color {
    color: $text-color;
}

.u-float-none {
    float: none !important;
}

.u-visuallyhidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    width: 1px;
    height: 1px;
    padding: 0;
    white-space: nowrap;
    border: 0;

    clip-path: inset(50%);
}
