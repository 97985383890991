.o-footer {
    background-color: $brand-primary;
    border-bottom-right-radius: 37px;
    color: $gray-base;
    margin-top: $element-divider-margin * 3;
    width: 100%;

    a:hover {
        text-decoration: underline;
    }

    .o-footer__brand {
        display: flex;
        height: 65px;
        justify-content: center;

        @media (min-width: $screen-sm-min) {
            justify-content: end;
        }
    }

    .o-footer__brand-image {
        width: 140px;
        height: 100%;
        background-image: url('../Images/Svg/logo-stadt-freising-schwarz.svg');
        background-size: contain;
        background-position: 50% 50%;
        position: relative;
    }

    .o-footer__brand-text {
        align-items: center;
        display: flex;
        font-size: $font-size-small;
        font-style: italic;
        padding: {
            right: 1.5rem;
        }
    }

    nav {
        text-align: center;
        margin: {
            top: 1.5rem;
            bottom: 1.5rem;
        }

        @media (min-width: $screen-sm-min) {
            align-items: center;
            display: grid;
            grid-template-columns: repeat(2, auto);
            text-align: left;
        }

        ul {
            @media (min-width: $screen-sm-min) {
                margin-bottom: 0;
            }
        }
    }
}
