//
// main
// --------------------------------------------------
//

.t-main {
    margin-top: $header-bottom-margin;

    > .tx-dce-pi1:first-of-type .o-slider {
        margin-top: - $header-bottom-margin;
    }

    .m-breadcrumb + .tx-dce-pi1:first-of-type .o-slider {
        margin-top: 0;
    }

    > .container {
        margin-top: $element-divider-margin;
    }

    p a {
        @include link-icon-right;
        text-decoration: underline;
        position: relative;
        margin-right: 1.4rem;
        word-break: break-word;

        &:hover {
            border-bottom-color: $brand-info;
        }
    }

    p a::after {
        position: relative;
    }

    .frame-type-sfsitepackagetourismus_slider > .container > .row {
        @media (min-width: $screen-sm-min) {
            margin: {
                right: 0;
                left: 0;
            }
        }
    }

    .webmaincontent.container .frame-type-sfsitepackagetourismus_slider > .container {
        padding: {
            right: 0;
            left: 0;
        }
        width: 100%;
    }
}

.t-main p .rspkr_dr_link::after { // sass-lint:disable-line class-name-format
    display: none;
}
