//
// breadcrumb
// --------------------------------------------------
//

.m-breadcrumb {
    line-height: 40px;
    margin-left: 0;
    grid-row: 1;
    grid-column: 1;

    a {
        color: $text-color;

        &:hover {
            text-decoration: underline;
        }
    }

    li {
        padding: 0;

        &:not(:last-child)::after {
            font-family: $font-icons;
            margin: 0 10px;
            content: '\f105';
        }
    }
}
