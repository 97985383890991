//
// list downloads
// --------------------------------------------------
//

.m-list-downloads {
    li {
        margin: 0.5em 0;
    }
}
