//
// search page
// --------------------------------------------------
//

.t-search {
    .t-search__form {
        margin-bottom: $element-divider-margin;
    }

    .t-search__results-nr {
        margin-top: $element-divider-margin * 2;
    }
}
