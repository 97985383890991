#freising-inline-datepicker * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
#freising-inline-datepicker table.ui-datepicker-calendar {
    margin: 0 auto;
}
#freising-inline-datepicker .ui-datepicker-header {
    position: relative;
    display: block;
    width: 100%;
}
#freising-inline-datepicker .ui-datepicker-header::after {
    display: table;
    content: '';
    clear: both;
}
#freising-inline-datepicker .ui-datepicker-header .ui-datepicker-title {
    display: block;
    position: relative;
    text-align: center;
    z-index: 2;
    margin-left: 40px;
    margin-right: 40px;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    color: #e95d0f;
}
#freising-inline-datepicker .ui-datepicker-header .ui-datepicker-prev,
#freising-inline-datepicker .ui-datepicker-header .ui-datepicker-next {
    width: 40px;
    white-space: nowrap;
    vertical-align: middle;
    display: inline-block;
    position: absolute;
    text-align: center;
    font-size: 28px;
    border: none !important;
    cursor: pointer;
    color: #e95d0f;
}
#freising-inline-datepicker .ui-datepicker-header .ui-datepicker-prev {
    left: 0;
    top: 0;
}
#freising-inline-datepicker .ui-datepicker-header .ui-datepicker-next {
    right: 0;
    top: 0;
}
#freising-inline-datepicker .ui-datepicker-header .ui-datepicker-prev.ui-state-disabled,
#freising-inline-datepicker .ui-datepicker-header .ui-datepicker-next.ui-state-disabled {
    visibility: hidden;
    cursor: default;
}
#freising-inline-datepicker .ui-datepicker-header .ui-datepicker-prev .ui-icon,
#freising-inline-datepicker .ui-datepicker-header .ui-datepicker-next .ui-icon {
    visibility: hidden;
    display: none;
}
#freising-inline-datepicker .ui-datepicker-header .ui-datepicker-prev::before,
#freising-inline-datepicker .ui-datepicker-header .ui-datepicker-next::before {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#freising-inline-datepicker .ui-datepicker-header .ui-datepicker-prev::before {
    content: "\f104";
}
#freising-inline-datepicker .ui-datepicker-header .ui-datepicker-next::before {
    content: "\f105";
}
#freising-inline-datepicker table.ui-datepicker-calendar th,
#freising-inline-datepicker table.ui-datepicker-calendar td {
    padding: 0 !important;
}
#freising-inline-datepicker table.ui-datepicker-calendar th span,
#freising-inline-datepicker table.ui-datepicker-calendar th a,
#freising-inline-datepicker table.ui-datepicker-calendar td span,
#freising-inline-datepicker table.ui-datepicker-calendar td a {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
}
#freising-inline-datepicker table.ui-datepicker-calendar th span:hover,
#freising-inline-datepicker table.ui-datepicker-calendar th a:hover,
#freising-inline-datepicker table.ui-datepicker-calendar td span:hover,
#freising-inline-datepicker table.ui-datepicker-calendar td a:hover {
    border: none !important;
}
#freising-inline-datepicker table.ui-datepicker-calendar th {
    color: #e58651;
}
#freising-inline-datepicker table.ui-datepicker-calendar th a,
#freising-inline-datepicker table.ui-datepicker-calendar th a:visited,
#freising-inline-datepicker table.ui-datepicker-calendar th a:focus,
#freising-inline-datepicker table.ui-datepicker-calendar td a,
#freising-inline-datepicker table.ui-datepicker-calendar td a:visited,
#freising-inline-datepicker table.ui-datepicker-calendar td a:focus {
    color: #333;
    font-weight: 600;
}
#freising-inline-datepicker table.ui-datepicker-calendar td a.ui-state-active {
    color: #e95d0f;
    background-color: rgba(255, 255, 255, 0.5);
}
#freising-inline-datepicker table.ui-datepicker-calendar td.ui-state-disabled span {
    color: #9E9E9E;
}