// Utilities
@import '00_utilities/variables';
@import '00_utilities/mixins';
@import '00_utilities/helpers';
@import '00_utilities/placeholders/focus-state';


// Vendor styles and related custom styles
@import '../../node_modules/slick-carousel/slick/slick.css';
@import '../../node_modules/magnific-popup/dist/magnific-popup.css';
@import 'vendor/bootstrap/bootstrap';
@import 'vendor/bootstrap/bootstrap-custom';
@import 'vendor/font-awesome/font-awesome';
@import 'vendor/slick-slider/slick-slider-custom';
@import 'vendor/MagnificPopup/index';
@import 'vendor/typo3';
@import 'vendor/powermail';
@import 'vendor/calendar';
@import 'vendor/sf-cookieconsent';
@import 'vendor/ReadSpeaker/index';


// Base rules are used to set the “base” styles of elements
@import '01_base/type';

// The atoms of our interfaces serve as the foundational building
// blocks that comprise all our user interfaces
@import '02_atom/brand-image';
@import '02_atom/buttons';
@import '02_atom/header-img';
@import '02_atom/icon';
@import '02_atom/image-subtitle';
@import '02_atom/link-back';
@import '02_atom/link-download';

// Molecules are relatively simple groups of UI elements functioning together as a unit
@import '03_molecule/breadcrumb';
@import '03_molecule/card';
@import '03_molecule/contrast-switch';
@import '03_molecule/dropdown-menu';
@import '03_molecule/figure';
@import '03_molecule/link-map';
@import '03_molecule/list-download';
@import '03_molecule/language-switcher';
@import '03_molecule/news-list-article';
@import '03_molecule/pagination';
@import '03_molecule/scrolltop-btn';
@import '03_molecule/searchbox';
@import '03_molecule/search-results';
@import '03_molecule/teaser';

// Organisms are relatively complex UI components composed of groups of
// molecules and/or atoms and/or other organisms
@import '04_organism/cardcontainer';
@import '04_organism/footer';
@import '04_organism/gallery';
@import '04_organism/lightbox';
@import '04_organism/main-content-header';
@import '04_organism/metanav';
@import '04_organism/navigation';
@import '04_organism/slider';
@import '04_organism/teaserbox';
@import '04_organism/subcontent';

// Templates are page-level objects that place components into a layout
// and articulate the design's underlying content structure
@import '05_template/main';
@import '05_template/news-details';
@import '05_template/news-list';
@import '05_template/search';


@import '06_pages/increased-contrast-mode';

//
// base
// --------------------------------------------------
//

header,
footer {
    body {
        font-family: $font-family-sans-serif;
        font-size: $font-size-base;
        font-weight: $font-weight-light;
        line-height: $line-height-base;
        position: relative;
        height: 100%;
        min-height: 100vh;
    }

    a {
        &:hover {
            text-decoration: none;
        }
    }

    hr {
        border-top: 2px solid $brand-primary;
    }

    figure {
        position: relative;
        display: table;
    }

    figcaption {
        font-size: $font-size-large;
        position: absolute;
        right: 0;
        top: 140px;
        bottom: auto;
        width: 100%;
        padding: 10px 20px;
        text-align: right;
        color: $white;
        background: $teaser-headline-bg;
        text-shadow: $teaser-headline-text-shadow;

        @media (min-width: $screen-xs-min) {
            padding: 10px 5px;
        }

        @media (min-width: $screen-sm-min) {
            padding: 10px 20px;
        }
    }

    strong {
        font-weight: $font-weight-regular;
    }
}

.tp-wrapper {
    a,
    a:hover,
    a:focus,
    .tp-detail .tp-header-headline,
    .tp-panel-headline,
    .tp-standalone-navbar-link:hover,
    .tp-standalone-navbar-link:focus,
    .tp-address-block-inclusive-services .tp-icon-ok:before,
    .tp-congress-room-item .tp-icon-ok:before,
    .tp-quickfinder-facets .tp-panel-showmore .tp-panel-headline {
        color: $text-color;
        text-decoration: underline !important;
    }

    .tp-btn-delete,
    .tp-btn-ticket,
    .tp-btn-demand-htg,
    .tp-btn-clipboard-add,
    .tp-btn-clipboard-remove,
    .tp-results-header-btn-clipboard a.pure-button,
    .tp-btn-bookmark,
    .tp-btn-host,
    .tp-btn-details-alternative,
    .tp-btn-backtowebsite,
    .tp-btn-sendcode,
    .tp-btn-ukv-house-link,
    .tp-btn-showroute,
    .tp-btn-sendform,
    .tp-btn-booking,
    .tp-badge-btn,
    .tp-btn-call,
    .tp-btn-details,
    .tp-btn-search,
    .tp-btn-demand,
    .tp-btn-register,
    .tp-btn-email,
    .tp-btn-add,
    .tp-results-header .pure-button-active,
    .tp-results-header a.pure-button-active,
    .tp-btn-back,
    .tp-btn-print,
    .tp-btn-participant,
    .tp-calendar-bestprice-offer {
        color: $btn-primary-color !important;

        &:hover,
        &:focus {
            background-color: $btn-primary-hover-bg !important;
            border-color: $btn-primary-hover-border !important;
            color: $btn-primary-hover-color !important;
        }
    }
}

#tp-clipboard-modal {
    .tp-btn-clipboard-show,
    .tp-btn-clipboard-close {
        color: $btn-primary-color !important;

        &:hover,
        &:focus {
            background-color: $btn-primary-hover-bg !important;
            border-color: $btn-primary-hover-border !important;
            color: $btn-primary-hover-color !important;
        }
    }
}
