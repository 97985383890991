//
// link to map
// --------------------------------------------------
//

.m-link-map {
    @include clearfix;
    margin-top: 10px - $element-divider-margin;
    margin-bottom: 10px + $element-divider-margin;

    .a-btn {
        float: right;
    }
}
