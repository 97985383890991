//
// To Top Anchor
// --------------------------------------------------

$topanchor-dimensions: 35px;

.m-scrolltop-btn {
    position: fixed;
    z-index: 1;
    right: 1.5rem;
    bottom: 2rem;
    display: block;
    width: $topanchor-dimensions;
    height: $topanchor-dimensions;
    margin-right: 0;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;

    .a-btn:hover,
    .a-btn:focus {
        background-color: $gray-base;
        color: $btn-secondary-color;
    }

    @media (min-width: $screen-xs-min) {
        bottom: 9rem;
    }

    @media (min-width: $screen-sm-min) {
        bottom: 11rem;
    }

    @media (min-width: $screen-lg-min + $topanchor-dimensions) {
        right: calc((100vw - #{$container-large-desktop}) / 2 - #{$topanchor-dimensions});
        bottom: 6rem;
    }

    &.visible {
        opacity: 1;
    }
}
