//
// powermail forms
// --------------------------------------------------
//

.powermail_fieldwrap {
    margin-bottom: $form-group-margin-bottom;
}

.powermail_captchaimage {
    margin-top: $form-group-margin-bottom;
}
