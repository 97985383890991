//
// mixins
// --------------------------------------------------
//

@mixin link-icon-right {
    &::after {
        font-family: $font-icons;
        content: '\f0da';
        position: absolute;
        right: -1rem;
        top: 50%;
        transform: translateY(-50%);
    }

    &::before {
        content: none;
    }
}

@mixin link-icon-left {
    &::before {
        font-family: $font-icons;
        margin-right: 5px;
        content: '\f0d9';
    }

    &::after {
        content: none;
    }
}

@mixin object-fit($image: img, $type: cover, $position: 50% 50%) {
    overflow: hidden;
    position: relative;
    $image: unquote($image);

    #{$image} {
        width: 100%;
        height: 100%;
        object-fit: $type;
        object-position: $position;
    }
}

// inline-svg´s as background-image
@mixin inline-svg($width, $height, $icon, $icon-hover: $icon, $position: left, $distance: 3px, $bottom: auto, $display: inline, $top: auto) {
    position: relative;
    padding-#{$position}: $width + $distance;
    display: $display;

    &::before {
        content: '';
        display: inline-block;
        position: absolute;
        width: $width;
        height: $height;
        bottom: $bottom;
        top: $top;
        #{$position}: 0;
        background: transparent url($inline-svg-path + $icon + '.svg') left center no-repeat;
    }

    &:hover::before,
    &:focus::before {
        background: transparent url($inline-svg-path + $icon-hover + '.svg') left center no-repeat;
    }
}
