// sass-lint:disable class-name-format
.rspkr_dr_link {
    &:hover,
    &:focus {
        background-color: transparent;
    }
}

.readspeaker-button.rsbtn {
    --button-bg: #{$readspeaker-big-button-bg-invers};
    --button-bg-light: #{$white};
    --button-color: #{$readspeaker-big-button-color-invers};
    --button-hover-bg: #{$readspeaker-big-button-hover-bg-invers};
    --button-hover-color: #{$readspeaker-big-button-hover-color-invers};

    @media (min-width: $screen-md) {
        --button-bg: #{$readspeaker-big-button-bg};
        --button-bg-light: #{$gray-lighter};
        --button-color: #{$readspeaker-big-button-color};
        --button-hover-bg: #{$readspeaker-big-button-hover-bg};
        --button-hover-color: #{$readspeaker-big-button-hover-color};
    }

    &.readspeaker-button--big {
        box-sizing: border-box;
        color: var(--button-color);
        grid-row: 1;
        grid-column: 2;
        justify-self: end;

        a,
        span {
            color: inherit;
        }

        .rsbtn_play {
            @include inline-svg(40px, 18px, readspeaker-black, readspeaker, right, 58px, auto, block);
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--button-color);
            background-color: var(--button-bg-light);
            border: 1px solid $btn-secondary-border;
            height: $readspeaker-big-button-height;
            width: 2 * $readspeaker-big-button-height;
            border: {
                bottom-left-radius: $border-radius;
                bottom-right-radius: $border-radius;
            }

            @media (min-width: $screen-md) {
                background-color: $gray-lighter;
                width: 100%;
                border: 1px solid $gray-lighter;
                padding-inline-start: $readspeaker-big-button-height;
                padding-inline-end: 20px;

                &::before {
                    display: none;
                }
            }

            .rsbtn_text {
                color: currentColor;
                span {
                    font-weight: normal;
                }
            }

            &:hover,
            &:focus {
                background-color: var(--button-hover-bg);
                color: var(--button-hover-color);
                border: 1px solid $readspeaker-big-button-border;

                span {
                    background: none;
                }
            }

            &:focus {
                @extend %focus-state;
            }

            .rsbtn_text {
                color: currentColor;

                &::before,
                &::after {
                    content: none;
                }

                &::after {
                    color: $readspeaker-big-button-color;
                }

                span {
                    display: none;

                    @media (min-width: $screen-md) {
                        display: block;
                        color: currentColor;
                    }
                }
            }

            .rsbtn_left {
                height: auto;
                margin: 0;
            }

            .rsbtn_right {
                display: none;
            }

        }

        .rsbtn_tooltoggle {
            background-color: var(--button-bg);
            color: var(--button-color);
            border-color: $readspeaker-big-button-border-invers;
            border-right-color: $readspeaker-big-button-border;
            border-bottom-left-radius: $border-radius;
            left: 0;
            top: 0;
            font-size: $font-size-small;
            transition: none;
            width: $readspeaker-big-button-height;
            height: $readspeaker-big-button-height;

            @media (min-width: $screen-md) {
                border: 1px solid $readspeaker-big-button-border;
                border-right-color: $readspeaker-big-button-border-invers;
            }

            span {
                color: currentColor;
            }

            &:hover,
            &:focus {
                background-color: var(--button-hover-bg);
                color: var(--button-hover-color);

                .rsicn {
                    color: currentColor;
                }
            }

            .rsicn::before {
                font-size: 20px;
            }

            &:focus {
                @extend %focus-state;
            }
        }

        .rsbtn_toolpanel {
            position: absolute;
            top: $readspeaker-big-button-height;
            width: 100% !important; // sass-lint:disable-line no-important
            min-width: $readspeaker-big-button-width !important; // sass-lint:disable-line no-important

            .rsbtn_tools {
                border-color: $readspeaker-big-button-border;
                background-color: $readspeaker-big-button-bg;
                border-radius: $border-radius;

                .rsbtn_tools_inner .rsbtn_toolcontainer .rsbtn_toollist li .rsbtn_tool {
                    border-color: $readspeaker-big-button-hover-border;
                    color: $readspeaker-big-button-color;

                    &:hover,
                    &:focus {
                        color: $readspeaker-big-button-hover-color;
                        background-color: $readspeaker-big-button-hover-bg;
                    }
                }
            }
        }

        &.zoom-tooltoggler .rsbtn_tooltoggle {
            transform: none;

            .rsicn {
                font-size: inherit;
            }
        }

        .rs-player-loadindicator {
            left: 0 !important; // sass-lint:disable-line no-important
        }

        &.rsexpanded .rsbtn_play {
            padding: 0;
            border-right: 1px solid;
        }
    }
}

.rspkr_dr_link {
    table & .rspkr_dr_img {
        // sass-lint:disable no-important
        position: relative !important;
        margin: {
            right: 0.5em !important;
            left: 0.5em !important;
        }
        // sass-lint:enable no-important
    }
}

// sass-lint:enable class-name-format
