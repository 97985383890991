// Bottom Arrow Navigation
.slick-navigation {
    position: absolute;
    bottom: $spacing-width-base;
    right: 0;
    left: auto;
    width: auto;
    //max-width: $outer-content-width-desktop;
    transition: opacity $transition-inactive-slide;

    &:first-child {
        left: 0;
        right: auto;
    }

    &.slick-disabled {
        opacity: $opacity-inactive-slide;
    }

    .slick-arrow {
        position: absolute;
        z-index: 3;
        top: -26px;
        display: flex;
    }
}

// Centered Custom Paging (1 bis 3 von 5 Bildern)
.custom_paging {
    width: 100%;
    //max-width: $outer-content-width-desktop;
    margin: 0;
    padding: 2.5rem 0 0;
    list-style: none;
    text-align: center;

    li {
        padding-left: 0;
    }

    li:not(.slick-active) {
        display: none;
    }
}
.slick-navigation {
    position: absolute;
    display: flex;
    top: 50%;
    z-index: 1;
    text-align: center;
    padding: 0 1rem;

    .slick-arrow {
        position: relative;
        width: 3.2rem;
        height: 3.2rem;
        border: 0;
        overflow: hidden;
        text-indent: 200%;
        background-color: $brand-primary;
        transition: opacity 0.35s linear, background-color 0.35s ease-in-out;

        &:hover {
            background-color: $brand-info;
            opacity: 1;
        }
    }

    .slick-next {
        margin-left: auto;
    }
}
