//
// typo3 elements that need additional styling
// --------------------------------------------------
//

.ce-textpic {
    @include clearfix;
    margin-bottom: $element-divider-margin;
}

.ce-gallery {
    .ce-left &,
    .ce-right & {
        float: none;
    }

    .ce-right & {
        @media (min-width: $screen-sm-min) {
            float: right;
        }
    }

    .ce-left & {
        @media (min-width: $screen-sm-min) {
            float: left;
        }
    }

    .ce-above &,
    .ce-below & {
        text-align: center;
    }

    .ce-below & {
        margin-top: $typo-element-spacing;
    }

    .ce-above & {
        margin-bottom: $typo-element-spacing;
    }

    .ce-column:not(:last-child) {
        @media (max-width: $screen-xs-min - 1) {
            margin-bottom: 10px;
        }
    }

    .ce-column,
    .ce-column figure {
        @media (max-width: $screen-xs-min - 1) {
            width: 100%;
        }
    }

    .ce-column img {
        @media (max-width: $screen-xs-min - 1) {
            width: 100%;
            height: auto;
        }
    }

    .ce-intext.ce-right & {
        margin-top: $typo-element-spacing;
        margin-left: 0;

        @media (min-width: $screen-sm-min) {
            margin-left: $typo-element-spacing;
        }
    }

    .ce-intext.ce-left & {
        margin-right: 0;

        @media (min-width: $screen-sm-min) {
            margin-right: $typo-element-spacing;
        }
    }

    .ce-media .m-lightbox-trigger,
    figure .m-lightbox-trigger {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;

        &::after {
            font-family: $font-icons;
            font-size: $font-size-small;
            line-height: 40px;
            position: absolute;
            right: 10px;
            bottom: 10px;
            width: 40px;
            height: 40px;
            content: '\f002';
            text-align: center;
            color: $white;
            border-radius: 50%;
            background: rgba($gray-base, $bg-opacity);
        }

        &:hover::after {
            background: $brand-primary;
        }
    }

    & .m-lightbox-trigger + figcaption {
        display: none;
    }
}

//
// pagination search results
// -------------------------
//

.tx-indexedsearch-browsebox {
    @extend .m-pagination;

    li {
        margin: 0 2px;
    }

    .tx-indexedsearch-browselist-currentPage {
        @extend .m-pagination__current;
    }
}
