//
// news article
// --------------------------------------------------
//


.m-news-list-article {
    @media (min-width: $screen-xs-min) {
        display: flex;
        flex-direction: row;
    }

    img {
        margin-right: 20px;
    }

    .m-news-list__article-content {
        display: flex;
        flex-direction: column;
    }

    .m-news-list__article-content footer {
        margin-top: auto;
        color: $gray-light;
    }
}
