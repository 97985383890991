//
// contrast switch
// --------------------------------------------------
//

.m-contrast-switch {
    border: 1px solid transparent;
    background: none;

    svg {
        fill: $navbar-inverse-color;
        margin: {
            left: 0.5em;
            right: 0.5em;
        }

        @media (min-width: $screen-sm-min) {
            margin-left: 0;
        }
    }

    .m-contrast-switch__text {
        @media (max-width: $screen-xs-max) {
            position: absolute;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            width: 1px;
            height: 1px;
            padding: 0;
            white-space: nowrap;
            border: 0;

            clip-path: inset(50%);
        }
    }

    &:hover {
        color: $navbar-inverse-link-hover-color;
        background-color: $navbar-inverse-link-hover-bg;

        svg {
            fill: $navbar-inverse-link-hover-color;
        }
    }

    &:focus,
    &:active {
        color: $navbar-inverse-link-hover-bg;
        border-color: $navbar-inverse-link-hover-bg;
        background-color: $navbar-inverse-link-hover-color;
        outline: 0;

        svg {
            fill: $navbar-inverse-link-hover-bg;
        }
    }

    .m-contrast-switch__increase,
    .m-contrast-switch__decrease {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .m-contrast-switch__decrease {
        display: none;
    }
}
